table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: auto;
    height: 93vh;
    table-layout: fixed;
  }
  

  
.Updatedtime{
    float: right;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 30px;
    margin-bottom: 10px;
}
.version{
  float: left;
  font-size: 1rem;
  
}

  table caption {
    font-size: 1.5rem;
    margin: .5em 0 .75em;
  }
  
  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }
  table th {
    padding: .600em;
    text-align: center;
    /* font-size: 2rem; */
    font-weight: 550;
    border: 1px solid #ccc;
  }
  

  table td {
    padding: .270em;
    text-align: center;
    font-size: 2.2rem;
    font-weight: 550;
    border: 1px solid #ccc;

  }

  /* // table even rows styling */
    table tr:nth-of-type(even) {
        background-color: #D9BE80;
    }
  
  table th {
    font-size: 1.7rem;
    /* letter-spacing: .1em; */
    /* text-transform: uppercase; */
    border: 1px solid #ccc;
    font-weight: bold;
    color: white;
    background-color: #013c8a;


  }
  
  @media screen and (max-width: 500px) {
    table {
      border: 0;
    }
  
    table caption {
      font-size: 1.3em;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
      
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }
  
  
  
  
  /* general styling */
  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
  }



  /* Existing CSS code */

/* Add media query for screens up to 14 inches */

  
  /* Additional existing CSS code */
  

  /* Add media query for screens up to 14 inches */

