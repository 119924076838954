.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.marquee {
  display: flex;
}

.tab1 {
  background-color: #d9be80;
  display: inline-block;
  height: 4.6rem;
  width: 20rem;
  width: 18rem;
  border-right: 4px solid white;
  overflow: hidden;
  margin-top: 4px;
}

.tab {
  background-color: #d9be80;
  display: inline-block;
  height: 4.6rem;
  /* width: 100%; */
  border: 2px solid white;
  overflow: hidden;
  margin-top: 3px;
}

.tab2 {
  display: inline-block;
  background-color: #aa6c39;
  height: 5rem;
}
.text {
  color:  #060e3a;
  font-size: 1.65rem;
  display: flex;
  align-items: left;
  padding: 4px 20px 0px 20px;
  font-weight: 500;
  
}
.text1 {
  color: #060e3a;
  font-size: 1.65rem;
  display: flex;
  align-items: left;
  /* padding: 0px 20px 0px 20px; */
  padding: 0px 20px 0px 20px;

  font-weight: 500;
 
}
.marq {
  margin-top: 1px;
}
.table23{
  /* height: 90vh; */
}
.ant-table-cell{
  font-size:  1.5rem;
  font-weight: bold;
  border: 1px solid #ffffff;
  background-color: #013c8a;
}



.ant-table-tbody{
  /* border-color:  #111110; */
  /* border: 5px solid #ffffff; */


}

.ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1me4733).ant-table-wrapper .ant-table-thead >tr>td{
  border: 1px solid #d9be80; 
   background-color: #013c8a;
  color: white;
  border: none;
}
  

/* .ant-table-thead >tr>th
{
  background-color: #df380e;
  color: white;
  border: none;
} */


  .ant-table-tbody > tr:nth-child(even) > td {
    background:#d9be80;
  }
  .ant-table-tbody > tr:nth-child(odd) > td {
    background: #fff;
  }